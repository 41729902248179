@import '../../../../scss/theme-bootstrap';

.basic-carousel-formatter {
  position: relative;
  width: 100%;
  &.container-max-width--padded {
    .basic-carousel {
      &.enable-overflow {
        .slick-list {
          overflow: visible;
        }
      }
    }
  }
  &.gap-10 {
    .basic-carousel {
      .slick-list {
        .slick-slide {
          margin-#{$rdirection}: 10px;
        }
      }
    }
  }
  .basic-carousel {
    margin-bottom: 0;
    &.peak-preview {
      /* stylelint-disable declaration-no-important */
      .slick-list {
        padding-#{$rdirection}: 15% !important;
      }
      /* stylelint-enable declaration-no-important */
    }
  }
  .carousel-bottom-pane {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    margin: 10px 0;
    @include breakpoint($landscape-up) {
      display: block;
      flex-direction: unset;
    }
    .carousel-controls {
      order: -1;
      z-index: 10;
      position: relative;
      display: flex;
      justify-content: flex-end;
      margin-#{$rdirection}: 20px;
      margin-bottom: 10px;
      @include breakpoint($landscape-up) {
        margin-bottom: 0;
      }
      &.controls-margin-right-50 {
        margin-#{$rdirection}: 50px;
      }
      &.controls-margin-right-40 {
        margin-#{$rdirection}: 40px;
      }
      &.controls-margin-right-30 {
        margin-#{$rdirection}: 30px;
      }
      &.controls-margin-right-20 {
        margin-#{$rdirection}: 20px;
      }
      &.controls-margin-right-10 {
        margin-#{$rdirection}: 10px;
      }
      &.controls-margin-right-0 {
        margin-#{$rdirection}: 0;
      }
      &.carousel-controls-left {
        justify-content: #{$ldirection};
      }
      &.carousel-controls-center {
        justify-content: center;
      }
      &.carousel-controls-right {
        justify-content: #{$rdirection};
      }
      @include breakpoint($landscape-up) {
        margin-#{$rdirection}: 0;
      }
      * {
        position: unset;
        transform: unset;
      }
      .carousel-controls-bg {
        display: flex;
        align-items: center;
        padding-block: 11px;
        padding-inline: 12px;
        gap: 16px;
        border-radius: 20px;
        button {
          position: absolute;
          @include breakpoint($landscape-up) {
            margin-top: 2px;
            transform: scale(0.4);
          }
        }
        .slick-prev-button {
          order: 1;
          cursor: pointer;
        }
        .slick-dots {
          order: 2;
          width: unset;
          li {
            height: 7px;
            width: 7px;
            @include breakpoint($landscape-up) {
              height: 12px;
              width: 12px;
            }
          }
        }
        .slick-next-button {
          order: 3;
          cursor: pointer;
        }
        .slick-prev-button,
        .slick-next-button {
          @if $text-direction == rtl {
            transform: rotate(180deg);
          }
          display: flex;
          align-items: center;
        }
        .slick-disabled {
          opacity: 0.4;
        }
      }
    }
  }
  .icon--chevron,
  .icon--chevron--left {
    width: 20px;
    height: 20px;
    fill: $color-black;
  }
  &:not(.basic-carousel-formatter--grid-display) {
    .basic-carousel__counter {
      display: none;
    }
  }
  &--hover-arrows {
    position: absolute;
    z-index: 2;
    @include breakpoint($landscape-up) {
      height: 85%;
      width: 35%;
    }
  }
  &--hover-arrows--right {
    content: url('#{$base-theme-path}img/icons/src/hover-arrows-right-light.svg');
    width: 30px;
    height: 21px;
    #{$rdirection}: 32px;
    top: 40px;
    @if $text-direction == rtl {
      content: '';
    }
    @include breakpoint($landscape-up) {
      #{$rdirection}: 0;
      top: 0;
      width: 40%;
      height: 85%;
      cursor: url('#{$base-theme-path}img/icons/src/hover-arrows-right-light.svg') 42 26, pointer;
      content: '';
    }
  }
  &--hover-arrows--left {
    @if $text-direction == rtl {
      content: url('#{$base-theme-path}img/icons/src/hover-arrows-left-light.svg');
      height: 21px;
      width: 32px;
      #{$ldirection}: 32px;
      top: 40px;
    } @else {
      content: '';
    }
    @include breakpoint($landscape-up) {
      #{$ldirection}: 0;
      width: 40%;
      height: 85%;
      cursor: url('#{$base-theme-path}img/icons/src/hover-arrows-left-light.svg') 42 26, pointer;
      content: '';
    }
  }
  &--hover-arrows--right.basic-carousel-formatter--hover-arrows--black {
    content: url('#{$base-theme-path}img/icons/src/hover-arrows-right-dark.svg');
    #{$rdirection}: 32px;
    top: 40px;
    height: 21px;
    width: 32px;
    @if $text-direction == rtl {
      content: '';
    }
    @include breakpoint($landscape-up) {
      #{$rdirection}: 0;
      top: 0;
      width: 40%;
      height: 85%;
      cursor: url('#{$base-theme-path}img/icons/src/hover-arrows-right-dark.svg') 42 26, pointer;
      content: '';
    }
  }
  &--hover-arrows--left.basic-carousel-formatter--hover-arrows--black {
    @if $text-direction == rtl {
      content: url('#{$base-theme-path}img/icons/src/hover-arrows-left-dark.svg');
      #{$ldirection}: 32px;
      top: 40px;
      height: 21px;
      width: 32px;
    } @else {
      content: '';
    }
    @include breakpoint($landscape-up) {
      #{$ldirection}: 0;
      width: 40%;
      height: 85%;
      cursor: url('#{$base-theme-path}img/icons/src/hover-arrows-left-dark.svg') 42 26, pointer;
      content: '';
    }
  }
  &--grid-display {
    &.basic-carousel-formatter--grid-cols-2 {
      .slick-slide {
        @include breakpoint($landscape-up) {
          margin: 0 0.27%;
        }
      }
      &.container-max-width--padded {
        @include breakpoint($landscape-up) {
          padding-#{$ldirection}: calc(#{$container-pad-landscape} - 1.8%);
          padding-#{$rdirection}: calc(#{$container-pad-landscape} - 1.8%);
        }
      }
      .basic-carousel__header {
        @include breakpoint($landscape-up) {
          padding-#{$ldirection}: 1.8%;
          padding-#{$rdirection}: 1.8%;
        }
      }
    }
    &.basic-carousel-formatter--grid-cols-3 {
      .slick-slide {
        @include breakpoint($landscape-up) {
          margin: 0 0.2%;
        }
      }
      &.container-max-width--padded {
        @include breakpoint($landscape-up) {
          padding-#{$ldirection}: calc(#{$container-pad-landscape} - 1%);
          padding-#{$rdirection}: calc(#{$container-pad-landscape} - 1%);
        }
      }
      .basic-carousel__header {
        @include breakpoint($landscape-up) {
          padding-#{$ldirection}: 1%;
          padding-#{$rdirection}: 1%;
        }
      }
    }
    &.basic-carousel-formatter--grid-cols-4 {
      .slick-slide {
        @include breakpoint($landscape-up) {
          margin: 0 0.17%;
        }
      }
      &.container-max-width--padded {
        @include breakpoint($landscape-up) {
          padding-#{$ldirection}: calc(#{$container-pad-landscape} - 0.7%);
          padding-#{$rdirection}: calc(#{$container-pad-landscape} - 0.7%);
        }
      }
      .basic-carousel__header {
        @include breakpoint($landscape-up) {
          padding-#{$ldirection}: 0.7%;
          padding-#{$rdirection}: 0.7%;
        }
      }
    }
    .basic-carousel__counter {
      position: absolute;
      #{$rdirection}: 20px;
      bottom: 0;
      font-family: $font--lars;
      font-size: 14px;
      line-height: 3em;
      display: none;
    }
    .basic-carousel__header {
      position: relative;
      @include breakpoint($landscape-up) {
        position: unset;
      }
    }
  }
  &--homepage-display,
  &--campaign-display {
    .carousel-footer-wrapper {
      position: absolute;
      width: 100%;
      bottom: 80px;
      @include breakpoint($landscape-up) {
        top: 50%;
        transform: translateY(-50%);
        bottom: initial;
      }
      .basic-carousel__counter {
        display: none;
      }
      .carousel-controls {
        position: absolute;
        margin: 0;
        #{$rdirection}: 10px;
        bottom: 10px;
        @include breakpoint($landscape-up) {
          position: static;
          margin: initial;
          #{$rdirection}: initial;
        }
        .carousel-controls-bg {
          gap: 0;
          @include breakpoint($landscape-up) {
            padding: 0;
            gap: 16px;
          }
          .slick-next-button,
          .slick-prev-button {
            @include breakpoint($landscape-up) {
              position: absolute;
            }
            &::before {
              height: 33px;
              width: 33px;
            }
          }
        }
      }
      .slick-prev-button {
        @if $text-direction == rtl {
          transform: rotate(180deg);
        }
        @include breakpoint($landscape-up) {
          position: absolute;
          #{$ldirection}: 35px;
          top: -50%;
          transform: translateY(-50%);
          @if $text-direction == rtl {
            transform: rotate(180deg);
          }
        }
      }
      .slick-next-button {
        @if $text-direction == rtl {
          transform: rotate(180deg);
        }
        @include breakpoint($landscape-up) {
          position: absolute;
          #{$rdirection}: 35px;
          top: -50%;
          transform: translateY(-50%);
          @if $text-direction == rtl {
            transform: rotate(180deg);
          }
        }
      }
      .icon--chevron,
      .icon--chevron--left {
        width: 35px;
        height: 35px;
      }
      .slick-dots {
        display: block;
        position: static;
        margin: 0;
        #{$rdirection}: 10px;
        bottom: 10px;
        @include breakpoint($landscape-up) {
          position: absolute;
          #{$rdirection}: 40px;
          bottom: 20px;
        }
        li {
          @include breakpoint($landscape-up) {
            margin: 0 3px;
          }
        }
        button::before {
          transform: scale(0.9);
          @include breakpoint($landscape-up) {
            transform: scale(0.6);
          }
        }
        .slick-active button::before {
          transform: scale(1);
        }
      }
    }
  }
  &--campaign-display {
    .carousel-footer-wrapper {
      top: 50%;
      transform: translateY(-50%);
      bottom: initial;
      #{$rdirection}: initial;
      .carousel-controls {
        position: static;
        margin: initial;
        #{$rdirection}: initial;
        .carousel-controls-bg {
          .slick-prev-button {
            position: absolute;
            #{$ldirection}: 10px;
            top: -50%;
            transform: translateY(-50%);
            @if $text-direction == rtl {
              transform: rotate(180deg);
            }
          }
          .slick-next-button {
            position: absolute;
            #{$rdirection}: 10px;
            top: -50%;
            transform: translateY(-50%);
            @if $text-direction == rtl {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
  &--counter-display {
    .basic-carousel__counter {
      display: block;
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
  }
  &--arrows-dots-cream {
    .icon--chevron,
    .icon--chevron--left {
      fill: $color-cream;
    }
    .slick-dots button::before {
      background-color: $color-cream;
      border: none;
    }
  }
  &__cta {
    position: relative;
    text-align: center;
    @include breakpoint($landscape-up) {
      position: absolute;
      z-index: 11;
      #{$ldirection}: 45%;
    }
    &.center-cta {
      @include breakpoint($landscape-up) {
        margin-#{$ldirection}: 0;
        #{$ldirection}: 50%;
        transform: translateX(-50%);
      }
    }
    &.right-cta {
      text-align: #{$rdirection};
      margin-#{$rdirection}: 20px;
      @include breakpoint($landscape-up) {
        margin-#{$rdirection}: 0;
        #{$ldirection}: 45%;
      }
    }
    &.left-cta {
      text-align: #{$ldirection};
      @include breakpoint($landscape-up) {
        #{$ldirection}: 0;
      }
    }
    a {
      border-radius: 10px;
      display: inline-block;
    }
  }
}
